'use client';
import { connectorsForWallets } from '@rainbow-me/rainbowkit';
import {
  injectedWallet,
  metaMaskWallet,
  coinbaseWallet,
  // walletConnectWallet,
} from '@rainbow-me/rainbowkit/wallets';

import { configureChains, createClient, mainnet } from 'wagmi';
import { polygon, optimism } from 'wagmi/chains';
import { alchemyProvider } from 'wagmi/providers/alchemy';
import { jsonRpcProvider } from 'wagmi/providers/jsonRpc';
import { publicProvider } from 'wagmi/providers/public';
import { getDefaultProvider } from 'ethers';

import { rainbowMagicConnector } from './rainbowMagicConnector';

const { chains, provider, webSocketProvider } = configureChains(
  [mainnet, polygon, optimism],
  [
    jsonRpcProvider({
      rpc: (chain) => ({
        http: chain.id === 1 ? 'https://rpc.ankr.com/eth' : '',
      }),
    }),
    alchemyProvider({
      apiKey: process.env.NEXT_PUBLIC_ALCHEMY_API_KEY,
    }),
    publicProvider(),
    // infuraProvider({
    //   apiKey: process.env.INFURA_API || '4863a01c109248f2b744bb3f69596f55',
    // }),
  ],
);

const connectors = connectorsForWallets([
  {
    groupName: 'Wallet/Web3:',
    wallets: [
      injectedWallet({ chains }),
      // rainbowWallet({ chains }),
      metaMaskWallet({ chains }),
      coinbaseWallet({ chains, appName: 'SimpFi.Ai' }),
      // walletConnectWallet({ chains }),
    ],
  },
]);

const customConnectors = connectorsForWallets([
  {
    groupName: 'Familiar Login:',
    wallets: [rainbowMagicConnector({ chains }) as unknown as Wallet],
  },
]);

const client = createClient({
  autoConnect: true,
  connectors: () => {
    // return [...connectors()];
    return [...customConnectors(), ...connectors()];
  },
  provider: Object.assign(getDefaultProvider(), { chains: chains }),
  // webSocketProvider,
});

const WagmiConfigs = {
  chains,
  provider,
  // webSocketProvider,
  connectors,
  customConnectors,
  client,
};

export default WagmiConfigs;
