//./providers/wagmi/index.tsx
'use client';

import React from 'react';
import {
  RainbowKitAuthenticationProvider,
  RainbowKitProvider,
  darkTheme,
  DisclaimerComponent,
  // createAuthenticationAdapter,
} from '@rainbow-me/rainbowkit';
import { WagmiConfig } from 'wagmi';

// import { authMachine } from '#/xstate/user/authMachine';

import CustomAvatar from './customAvatar';
import useAuth from './useAuth';
import { useRedirectToDashboard } from './useRedirect';
import WagmiConfigs from './wagmiConfigs';
// import { useInterpret, useSelector } from '@xstate/react';

const client = WagmiConfigs.client;
const chains = WagmiConfigs.chains;

const Disclaimer: DisclaimerComponent = ({ Text, Link }) => (
  <Text>
    By connecting your wallet, you agree to the{' '}
    <Link href="https://SimpFi.ai/terms">Terms of Gangster</Link> and
    acknowledge you have read and understand the protocol{' '}
    <Link href="https://SimpFi.ai/legal">Disclaimer</Link>
  </Text>
);

// export const createAuthAdapter = (authAdapterService: any) => {
//   return createAuthenticationAdapter({
//     getNonce: async () => {
//       console.debug('[authAdapter] RUNNING `getNonce`');
//       authAdapterService.send({ type: 'GET_NONCE' });
//     },
//     createMessage: async ({
//       nonce,
//       address,
//       chainId,
//     }: {
//       nonce: string;
//       address: string;
//       chainId: number;
//     }) => {
//       console.debug('[authAdapter] RUNNING `createMessage`');
//       authAdapterService.send({
//         type: 'CREATE_MESSAGE',
//         nonce,
//         address,
//         chainId,
//       });
//     },
//     getMessageBody: ({ message }) => {
//       console.debug('[authAdapter] RUNNING `getMessageBody`');
//       authAdapterService.send({ type: 'GET_MESSAGE_BODY', message });
//     },
//     verify: ({ message, signature }) => {
//       console.debug('[authAdapter] RUNNING `verify`');
//       authAdapterService.send({ type: 'VERIFY', message, signature });
//     },
//     signOut: () => {
//       console.debug('[authAdapter] RUNNING `signOut`');
//       authAdapterService.send({ type: 'SIGN_OUT' });
//     },
//   });
// };

const WagmiProvider = ({ children }: { children: React.ReactNode }) => {
  const { authStatus, authAdapter } = useAuth();
  const shouldRedirect = authStatus === 'authenticated';
  useRedirectToDashboard(shouldRedirect);

  // const authAdapterService = useInterpret(authMachine).start();
  // const authStatus = useSelector(authAdapterService, (state) =>
  //   state.matches('authStatus'),
  // );
  // const authAdapter = useMemo(() => createAuthAdapter(authAdapterService), []);

  return (
    <WagmiConfig client={client}>
      <RainbowKitAuthenticationProvider
        adapter={authAdapter}
        status={authStatus}
      >
        <RainbowKitProvider
          coolMode
          chains={chains}
          modalSize="compact"
          theme={darkTheme({
            accentColor: '#26B400',
            accentColorForeground: 'white',
            borderRadius: 'small',
            fontStack: 'system',
            overlayBlur: 'large',
          })}
          appInfo={{
            appName: 'SimpFi ALPHA',
            disclaimer: Disclaimer,
            learnMoreUrl: 'https://SimpFi.ai',
          }}
          avatar={CustomAvatar}
        >
          {children}
        </RainbowKitProvider>
      </RainbowKitAuthenticationProvider>
    </WagmiConfig>
  );
};
export default WagmiProvider;
