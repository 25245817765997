// import { lightTheme, Theme } from '@rainbow-me/rainbowkit'
// 'use client';
import { MagicAuthConnector } from '@everipedia/wagmi-magic-connector';

export const rainbowMagicConnector = ({ chains }: any) => ({
  id: 'magic',
  name: 'Use Email Login',
  iconUrl: '/simpfinogrid.png',
  iconBackground: '#000',
  createConnector: () => {
    const connector = new MagicAuthConnector({
      chains,
      options: {
        apiKey: process.env.NEXT_PUBLIC_MAGIC_LINK_PK ?? '',
        accentColor: '#30E000',
        customLogo: '/simpfinogrid.png',
        isDarkMode: true,
        // headerText: 'Login to your account',
        magicSdkConfiguration: {
          network: {
            rpcUrl: 'https://rpc.ankr.com/eth',
            // 'https://eth-mainnet.g.alchemy.com/v2/YkMrK70EeccNI_futeQc1ka_yqi755xc', // your ethereum, polygon, or optimism mainnet/testnet rpc URL
            // 'https://eth-mainnet.g.alchemy.com/v2/e_i6CWjwpgVBo2KnsTNDN_v7hSSN6-Lw', // your ethereum, polygon, or optimism mainnet/testnet rpc URL
            // 'https://eth.llamarpc.com',
            chainId: 1,
          },
        },
      },
    });
    return {
      connector,
    };
  },
});
