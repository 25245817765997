// ./app/layout.tsx
'use client';

// Imports
// ========================================================
import Head from 'next/head';
import Script from 'next/script';
import '#/services/logrocket';
import ToastContainer from '~/Toast/ToastContainer';
import RootProvider from './providers';

// CSS Imports
// ========================================================
import 'reactflow/dist/base.css';
import '@rainbow-me/rainbowkit/styles.css';
import 'react-toastify/dist/ReactToastify.css';
import '#/styles/globals.css';

// Layout
// ========================================================
export default function RootLayout({ children }: { children: any }) {
  return (
    <>
      <html>
        <Head>
          <meta
            property="og:logo"
            content="https://alpha.simpfi.ai/simpfinogrid.png"
          />
        </Head>
        <Script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-K6M5DRQS1Z"
        ></Script>
        <Script id="ga-16bc295b">
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date()); gtag('config', 'G-K6M5DRQS1Z');
          `}
        </Script>
        <body className="bg-gray-1100 bg-[url('/grid.svg')] min-h-[100vh] overflow-y:hidden">
          <RootProvider>
            {children}
            <ToastContainer />
          </RootProvider>
        </body>
      </html>
    </>
  );
}
